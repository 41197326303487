import * as React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { instanceOf } from 'prop-types'
import { withCookies, Cookies } from 'react-cookie'
import Grid from '@material-ui/core/Grid'
import Layout from '../components/layout'
import Seo from '../components/seo'

import bannerimg from '../assets/images/tradingwithhand.jpg'

const MOBILE_BREAKPOINT = 721
const isClient = typeof window !== 'undefined'

class QuantAlgosPage extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  }
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      viewportWidth: isClient ? window.innerWidth : 0,
    }
  }
  updateWindowDimensions = () => {
    this.setState({ viewportWidth: window.innerWidth })
  }
  componentDidMount() {
    if (isClient) {
      this.updateWindowDimensions()
      window.addEventListener('resize', this.updateWindowDimensions)
    }
  }

  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const keywords = get(this, 'props.data.site.siteMetadata.keywords')
    const pageName = 'Quantitative Fund Algorithms'

    const { viewportWidth } = this.state
    const isMobile = Boolean(viewportWidth <= MOBILE_BREAKPOINT)
    var pagewidth = {
      matches: false,
      media: '(max-width: 600px)',
      onchange: null,
    }
    if (typeof window !== 'undefined') {
      pagewidth = window.matchMedia('(max-width: 600px)')
    }

    return (
      <React.Fragment>
        <Layout data={this.props} pagewidth={pagewidth.matches}>
          <Seo pagename={pageName + ' ' + siteTitle} keywords={keywords} />
          <div className="page_wrapper generalpage_wrapper">
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <h1 className="generalpage_majorheading">{pageName}</h1>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Grid container spacing={0}>
                      <Grid item xs={12}>
                        <p>
                          Quant funds utilise and depend on algorithmic or
                          systematically programmed investment strategies. They
                          grew in popularity as market data became more
                          accessible, relying on thousands of trading signals
                          ranging from economic data points to trending global
                          asset values and real-time company news. These
                          sophisticated strategies developed around momentum,
                          quality, value, and financial strength give quant
                          funds an incredible market edge and enable them to
                          predict price moves significantly earlier than retail
                          traders.
                          <br />
                          <br />
                          EdgeForecaster delivers these advanced algorithms to
                          its subscribers to give them the advantage usually
                          only available to quant funds. Coupled with short and
                          long term price forecasts, these algorithms extend the
                          market edge subscribers receive, resulting in
                          consistent profitable returns.
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Layout>
      </React.Fragment>
    )
  }
}

export default withCookies(QuantAlgosPage)
export const QuantAlgosPageQuery = graphql`
  query QuantAlgosPageQuery {
    site {
      siteMetadata {
        title
        description
        menuLinks {
          name
          link
          cl
        }
        keywords
      }
    }
    sitePage {
      pluginCreator {
        name
      }
    }
  }
`
